import { extendTheme, ThemeConfig } from "@chakra-ui/react";

const config: ThemeConfig = {
  initialColorMode: "dark",
  useSystemColorMode: false,
}
/*export default extendTheme({
  fonts: {
    heading: "Inter",
    body: "Inter",
  },
});*/
const theme = extendTheme({ config, 
  fonts: {
    heading: "Inter",
    body: "Inter",
  },
 })
export default theme
