import React, { useEffect, useRef} from 'react'
import { Link as ReactRouterLink, useHistory } from 'react-router-dom'
import contracts from "../contracts"

import {
    Flex,
    Container,
    Heading,
    Stack,
    Text,
    Button,
    Input,
    HStack,
    VStack,
    Box,
    Icon,
    IconProps,
    List,
    ListItem,
    Link,
  Image,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  } from '@chakra-ui/react';
import { useUserNFT } from '../hook/NFTProvider';

import FeatureTextBox from './FeatureTextBox'
import { useConfig, useEthers, getChainName } from '@usedapp/core';
import {useProvider, useNameOfNFT, useNFTContract, useOwnerNFT, useTokenOfOwnerNFT} from '../hook/use-owner-nft.hook';
import { getNetwork } from 'ethers/node_modules/@ethersproject/networks';
import { IndexType } from 'typescript';
var web3Contract = require('web3-eth-contract');

const avatars_bottts_sprites = require('@dicebear/avatars-bottts-sprites');
const { createAvatar } = require('@dicebear/avatars');
const subscribe_url = 'https://gmail.us14.list-manage.com/subscribe?u=6c7e6a0d40567601e0dd6095d&id=cbb4a791bb'
const opensea_url = 'https://opensea.io/collection/playablenftseries1'

export default function Center() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const provider = useProvider()
  const { account, chainId } = useEthers()
  const NFTContractAddress = contracts.getContractAddress('PlayableNFTSeries1Address', chainId)
  const nftContract: any = useNFTContract(NFTContractAddress)
  const prevNetworkId = usePrevious(chainId)
  const ownerNft = useOwnerNFT(account, chainId, NFTContractAddress)
  const nftList = useTokenOfOwnerNFT(account, chainId, NFTContractAddress, ownerNft)
  const nftName = useNameOfNFT(NFTContractAddress) //'NFTBloky' //useNameOfNFT() string.replace(/\s/g, '') to replace space
  const [networkChanged, setNetworkChanged] = React.useState(false)
//  let svgAvatar = createAvatar(avatars_bottts_sprites, { seed: 'PlayableNFT', size: 100 }) 

  // Logo: https://avatars.dicebear.com/api/bottts/myrobot.svg

    useEffect(() => {
      async function getOwnerNft() {
        if (nftContract !== undefined && ownerNft !== undefined) {
          for (let i = 0; i < ownerNft; i++) {
            const nfts = await nftContract.methods.tokenOfOwnerByIndex(account, i).call()
            console.log(`nfts addresses: 0x${nfts}`)
          }
        }
      }
      if (chainId !== prevNetworkId) {
        //window.location.reload()
        setNetworkChanged(true)
      }
      getOwnerNft()      
    }, [account, chainId, setNetworkChanged, ownerNft])

  const onGetStartedClick = () => {
      getStarted(opensea_url)
    }
    const checkNFTOwnerShip = () =>{
        console.log('checkNFT result:' /*+ result*/)
    }
  function usePrevious(value: any) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  let history = useHistory();
  const routeChange = () => {
    let path = `/learnmore`;
    history.push(path);
  }
  function learnMore() {
    routeChange()
  }

  function openSubscription() {
    onClose()
    window.open(subscribe_url)
  }

  function getStarted(url:string) {
    window.open(url)
  }

  return (
    <Container maxW={'5xl'}>
      {
         (chainId !== undefined) && window.location.hostname.indexOf('localhost') === -1 &&
          (window.location.hostname.indexOf('testnet') === -1) && (chainId.valueOf() !== 1) && (chainId.valueOf() !== 137) ?
          window.location.href='https://testnet.bitbikin.com'
          : () => { }
      } 
      {
        (chainId !== undefined) && window.location.hostname.indexOf('localhost') === -1 &&
          (window.location.hostname.indexOf('testnet') !== -1 || window.location.hostname.indexOf('localhost') !== -1 )&& (chainId.valueOf() === 1 || chainId.valueOf() === 137) ?
          window.location.href = 'https://bitbikin.com'
          : () => { }
      }

        <Stack
          textAlign={'center'}
          align={'center'}
          spacing={{ base: 8, md: 10 }}
        py={{ base: 10, md: 18 }}>
        <Box boxSize="xlg" py={5}>
          <Image src="./img/bicycle-racing.png" alt="biking NFT" width={618} height={615}/>
        </Box>          

          <Heading
            fontWeight={600}
            fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
            lineHeight={'110%'}>
            Bit{' '}
            <Text as={'span'} color={'orange.400'}>
              Biking
            </Text>
        </Heading>
        <Text color={'whatsapp.200'} maxW={'3xl'} fontWeight={400} fontSize={{ base: 'l', sm: '2xl', md: '3xl' }}
        >Coming Soon to Cycling Community. </Text>
        <Text align={'left'} >Follow us on twitter for our most recent progress and anouncement. More and more details will be revealed everyday. Don't miss out on this great opportunity.</Text>

        {/* <Text color={'gray.500'} maxW={'3xl'}>
            Check your Biking NFT             
            { account==null || account === undefined?
              '\n(connect to your wallet first)'
              : ''
            }
          </Text>
          <VStack spacing={4} align={'stretch'}>
            {chainId !== undefined ?
              < Text > Current network: {getChainName(chainId)}</Text>
              : ''
            }
              <Text>Wallet: {account}</Text>
              <Text>Biking NFT Owned: </Text>
              {(nftList !== undefined) ?
              <List>
                  {
                      nftList.map((key, item) => {
                          return (
                          <HStack 
                          textAlign={'center'}
                          spacing={{ base: 10, md: 15 }}
                          align={'center'}
                          justify={'center'}
                          key={item}
                            >                              
                              <Image src={`https://avatars.dicebear.com/api/bottts/${nftName}${key}.svg?size=100`} />
                              <Link as={ReactRouterLink} to={{ pathname: "/playnft", state: { wallet: account, nft: key, contract: NFTContractAddress, nftName: nftName, chainId: chainId as number, modal: true,}}} key={item}>{nftName} #{key}</Link>
                          </HStack>
                          )
                     })
                }
              </List>
              :null
            }  
          </VStack>
          */}
        {/*   <Stack spacing={6} direction={'row'}>
           <Button              
              rounded={'full'}
              px={6}
              colorScheme={'orange'}
              bg={'orange.400'}
            _hover={{ bg: 'orange.500' }} onClick={onGetStartedClick}>
                Get Started
            </Button>            
          <Button rounded={'full'} px={6} onClick={learnMore}>
              Learn more
            </Button>
        </Stack>
        */}
          <Flex w={'full'}>
          </Flex>
      </Stack>
      <Modal isOpen={isOpen} onClose={onClose} size={'lg'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Coming Soon to Major NFT Marketplaces</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>The airdrop date will be published on NFT calendar. Want to get notified personaly when the NFT is to be air dropped? Sign up for the notification.</Text>
          </ModalBody>

          <ModalFooter>
            <Button rounded={'full'} mr={10} px={6} onClick={onClose}>
              Close
            </Button>
            <Button rounded={'full'} px={6} colorScheme={'orange'}
              bg={'orange.400'}
              _hover={{ bg: 'orange.500' }} onClick={openSubscription}>Notify Me</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Container>
    );
  }