import NFTBloky from "../playables/NFTBloky/NFTBloky"
import { useConfig, useEthers, getChainName } from '@usedapp/core';

export const CreatureContractAddress = "0x9d0d5A104A1039ba176C8e3812B71bD46da09B4b"
export const DehorizonMMO1Address = "0xc13494337d68f51354e9a2e21b9643287a055ccf"
//export const PlayableNFTSeries1Address = '0x14A9c5e74Ee6E527D9d08d02F4933A22f846Ce95'

const contracts = {
    "Mainnet": {

    },    
    "Rinkeby": {
        'CreatureContractAddress': "0x9d0d5A104A1039ba176C8e3812B71bD46da09B4b",
        'DehorizonMMO1Address': "0xc13494337d68f51354e9a2e21b9643287a055ccf",
        'PlayableNFTSeries1Address': '0x14A9c5e74Ee6E527D9d08d02F4933A22f846Ce95'
    },
    "Polygon": {
        'PlayableNFTSeries1Address': '0xfbe489e731cA73256A466BA54E60f38b0074B962'        
    }
}
function getContract(network, name) {
    if (contracts[network] === null || contracts[network] === undefined ) return undefined
    return contracts[network][name]
}

function getContractAddress(contractName, chainId) {
    if (chainId === undefined || getChainName(chainId) === undefined || contractName === undefined || contractName == '') {
        return undefined
    }
    return getContract(getChainName(chainId), contractName)
}

export default { contracts, getContract, getContractAddress }
