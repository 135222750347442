import { ReactNode } from "react";
import { Flex } from "@chakra-ui/react";
import { useEffect, useState } from 'react'


type Props = {
  children?: ReactNode;
};

export default function Layout({ children }: Props) {
  return (
    <Flex
      flexDirection="column"
      h="100vh"
      bg="gray.900"
      justifyContent={'space-between'}
     >
      {children}
    </Flex>
  );
}
