import { ChakraProvider, useDisclosure, HStack } from "@chakra-ui/react";
import theme from "../theme";
import Layout from "../components/Layout";
import Center from "../components/Center";
import "@fontsource/inter";
import NavBar from "../components/NavBar";
import NFTProvider from "../hook/NFTProvider";
import Footer from "../components/Footer"

function Home() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <ChakraProvider theme={theme}>
      <Layout>
        <NavBar/>
        <Center />
        <Footer/>
      </Layout>
    </ChakraProvider>
  );
}

export default Home;
