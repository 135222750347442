import React, { useEffect, useCallback, useRef, useReducer } from 'react'
//import { defaultReducer } from './defaultReducer'
import { ethers, Contract, getDefaultProvider } from "ethers";
// import { chainId, useEthers,useContractCall,useContractFunction } from "@usedapp/core";
import crestureContractAbi from  "../abi/Creature.json" //"../abi/ERC721.json";
import DehorizonMMONFT1Abi from  "../abi/DehorizonMMONFT1.json" //"../abi/ERC721.json";
import PlayableNFTSeries1Abi from  "../abi/PlayableNFTSeries1.json" 
import { CreatureContractAddress, DehorizonMMO1Address, /*PlayableNFTSeries1Address*/ } from "../contracts"
import detectEthereumProvider from '@metamask/detect-provider';

require('dotenv').config();
const web3 = require("web3");
var web3Contract = require('web3-eth-contract');

// use infura
//web3Contract.setProvider('https://rinkeby.infura.io/v3/e7f4cf58e3d346ad8f187abf6f447a4f')

// use metamask
/*
const HDWalletProvider = require("@truffle/hdwallet-provider");
const NETWORK = process.env.NETWORK
const NODE_API_KEY = process.env.INFURA_KEY || process.env.ALCHEMY_KEY;
const isInfura = !!process.env.INFURA_KEY;

const MNEMONIC = process.env.MNEMONIC;
const network =
NETWORK === "mainnet" || NETWORK === "live" ? "mainnet" : "rinkeby";

const provider = new HDWalletProvider(
MNEMONIC,
isInfura
  ? "https://" + network + ".infura.io/v3/" + NODE_API_KEY
  : "https://eth-" + network + ".alchemyapi.io/v2/" + NODE_API_KEY
);

const web3Instance = new web3(provider);
*/

const creatureContractInterface = new ethers.utils.Interface(crestureContractAbi.abi);
const contract = new Contract(CreatureContractAddress, crestureContractAbi.abi);

const PlayableNFTSeries1Interface = new ethers.utils.Interface(PlayableNFTSeries1Abi.abi);
//const contractPlayableNFTSeries1 = new Contract(PlayableNFTSeries1Address, PlayableNFTSeries1Abi.abi);


const DehorizonMMO1Interface = new ethers.utils.Interface(DehorizonMMONFT1Abi.abi);
const contractDehorizonMMO1 = new Contract(DehorizonMMO1Address, DehorizonMMONFT1Abi.abi);


/*const nftContract = new web3Instance.eth.Contract(
  PlayableNFTSeries1Abi.abi,
  PlayableNFTSeries1Address,
  { gasLimit: "2000000" }
);
*/


/*export  function useOwnerNFT(owner) {
    let contract = PlayableNFTSeries1Address
    return {contract: contract, result: useContractCall({
            abi: PlayableNFTSeries1Interface,
            address: contract,
            method: "balanceOf",
            args: [owner],
          })}
}*/

/*export function useOwnerNFT(owner) {
  const [balance, setBalance] = React.useState(0)

  const balanceOf = useCallback(async () => {
    let result = useContractCall({
      abi: PlayableNFTSeries1Interface,
      address: contract,
      method: "balanceOf",
      args: [owner],
    })
    setBalance(result[0])
  }, [owner]);

  useEffect(async () => {
    balanceOf();
  }, [balanceOf]);

  return { contract: contract, result: [balance] }
}
*/

/*export  function useOwnerNFT(owner) {
  const [balance, setBalance] = React.useState(0)
  let contract = PlayableNFTSeries1Address

  function
  useEffect(()=>{
    let result = useContractCall({
      abi: PlayableNFTSeries1Interface,
      address: contract,
      method: "balanceOf",
      args: [owner],
    })
    setBalance(result[0])
    }, [owner])

  return {contract: contract, result: [balance]}
}*/
export function useProvider() {
  const [provider, setProvider] = React.useState(undefined)

  async function getProvider() {
    const _provider = await detectEthereumProvider();
    setProvider(_provider)
    web3Contract.setProvider(_provider)
  }
  useEffect(() => {
    if (provider === undefined) {
      getProvider()
    }
  })

  return provider
}

export function useNFTContract(contractAddress) {
  const [contract, setContract] = React.useState(undefined)
  //const provider = useProvider()

   function getContract() {
   /* const provider = await detectEthereumProvider();
    web3Contract.setProvider(provider)*/
    const bc = new web3Contract(PlayableNFTSeries1Abi.abi, contractAddress/*PlayableNFTSeries1Address*/)
    setContract(bc)
  }
  useEffect(() => {
    if (contractAddress !== undefined) {
      getContract()
    } /*else {
      setContract(undefined)
    }*/
  }, [contractAddress])

  return contract
}

export function useOwnerNFT(owner, chainId, contractAddress) {
  const [balance, setBalance] = React.useState(0)
  const prev = usePrevious(chainId)
  //const contract = useNFTContract(contractAddress)

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  async function balanceOf() {
    if (owner === undefined || chainId === undefined || contractAddress === undefined) {
      setBalance(0)
      return
    }
  //  if (prev === chainId) return
    /*const provider = await detectEthereumProvider();
    web3Contract.setProvider(provider)
    const bc = new web3Contract(PlayableNFTSeries1Abi.abi, PlayableNFTSeries1Address);
    */
    const contract = new web3Contract(PlayableNFTSeries1Abi.abi, contractAddress/*PlayableNFTSeries1Address*/)

    let result = await contract.methods
      .balanceOf(owner)  //function in contract
      .call()
    setBalance(result[0])
  }

  useEffect(() => {
      balanceOf();
  }, [owner, chainId]);

  return balance
}

export function useTokenOfOwnerNFT(owner, chainId, contractAddress, ownerNft) {
  const [tokens, setTokens] = React.useState([]);
  //const contract = useNFTContract(NFTContractAddress)
  //const [loading, setLoading] = React.useState("false");
  //web3Contract.setProvider(library)

  //var ownerNftCount = useRef(ownerNft.result[0])

    async function getOwnerTokens() {
      if (owner === undefined || contractAddress === undefined || ownerNft === undefined || ownerNft === 0) {
        setTokens([])
        return []
      }
   /*const provider = await detectEthereumProvider();
    web3Contract.setProvider(provider)
    const bc = new web3Contract(PlayableNFTSeries1Abi.abi, PlayableNFTSeries1Address);*/
    
    //setTokens(tokens=>({...tokens, tokens:[]}))
   // setLoading("true")
    var tokenList =[]
    for (var i = 0; i < ownerNft;i++) {
      console.log(`owner,i: ${owner}, ${i}`)
      const contract = new web3Contract(PlayableNFTSeries1Abi.abi, contractAddress/*PlayableNFTSeries1Address*/)

    let tokenid = await contract.methods
      .tokenOfOwnerByIndex(owner, i)  //function in contract
      .call()
    /*.send({
      from: window.web3.currentProvider.selectedAddress,  //should be the same as owner
      to: address,
      value: amount,
      gasPrice: '20000000000' 
    });*/

     //let tokenid = await bc.methods.tokenOfOwnerByIndex(owner,i).call();
     tokenList.push(tokenid);
     console.log(`token id: ${tokenid[0]} total: ${tokenList.length}`)
     /* await nftContract.methods.tokenOfOwnerByIndex(owner,i).call(function (error, result) {
        if (error) {
          console.log(error);
          setLoading("false")
        } else {
          tokens.push(result);
        }
      });
      */
    }
    setTokens(tokenList)
    return tokenList
  }

  useEffect(()=>{
      getOwnerTokens()
  }, [owner, chainId, ownerNft]);
  
  return tokens
}

/**
 * 
 * @returns Name of NFT
 */
export function useNameOfNFT(contractAddress) {
  const [name, setName] = React.useState('');

  async function getNFTName() {
    /*const provider = await detectEthereumProvider();
    web3Contract.setProvider(provider)*/
    const bc = new web3Contract(PlayableNFTSeries1Abi.abi, contractAddress/*PlayableNFTSeries1Address*/);
    let nftName = await bc.methods
    .name()  //function in contract
    .call()
    setName(nftName)
    return nftName
  }

  useEffect(() => {
    if (contractAddress !== undefined) {
      getNFTName()
    }
  }, [contractAddress]);
  
  return name
}

export default {
  useOwnerNFT,
  useTokenOfOwnerNFT,
  useNameOfNFT
}

