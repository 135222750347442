import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from 'react-router-dom'
import App from "./App";
import { DAppProvider } from "@usedapp/core";

ReactDOM.render(
    <DAppProvider config={{}}>
      <App />
    </DAppProvider>,
  document.getElementById("root")
);
