import { ChakraProvider, useDisclosure, HStack } from "@chakra-ui/react";
import { BrowserRouter as Router, Switch, Redirect, Route} from 'react-router-dom'
import theme from "./theme";
import Layout from "./components/Layout";
import ConnectButton from "./components/ConnectButton";
import AccountModal from "./components/AccountModal";
import Center from "./components/Center";
import Home from "./components/Home";
import LearnMore from "./components/LearnMore";
import "@fontsource/inter";
import NavBar from "./components/NavBar";
import NFTProvider from "./hook/NFTProvider";
import PlayNFT from "./PlayNFT"
import NFTPage from "./components/NFTPage";
import NFTBloky from "./playables/NFTBloky/NFTBloky"
import NFTBlokyMobile from "./playables/NFTBloky/NFTBlokyMobile"
import contracts from "./contracts"
import { useEthers } from '@usedapp/core';
import HowToPlay from "./components/HowToPlay";
import Faq from "./components/Faq"
import Team from "./components/Team";
//import { useNameOfNFT, useNFTContract, useOwnerNFT, useTokenOfOwnerNFT } from './hook/use-owner-nft.hook';

function App() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  //const nftContract: any = useNFTContract()
  const { account, chainId } = useEthers()
  //const ownerNft = useOwnerNFT(account, chainId)

  return (
    <> {
    <Router>
      <Switch>
          {/* This route is for home component 
          with exact path "/", in component props 
          we passes the imported component*/}
          <Route path="/" component={Home} exact />
          {/*<Route path="/learnmore" component={LearnMore} />
          <Route path="/playnft" component={PlayNFT} />*/}
          {/* account !== undefined && ownerNft !== undefined ? 
          <Route path="/NFTBloky" component={NFTBloky} />
        */} 
          {/*account !== undefined && ownerNft !== undefined ?
          <Route path="/NFTBlokyMobile" component={NFTBlokyMobile} />*/}
          {/*<Route path="/NFTPage/:id" component={NFTPage} /> */}
          {/*<Route path="/howtoplay" component={HowToPlay} />
          <Route path="/faq" component={Faq} />*/}
          {/*<Route path="/team" component={Team} /> */}
          <Redirect to="/" />
          {/*  : () =>  <Redirect to="/" /> */}
          
          {/*<Route path="/NFTBloky" render={() => <Redirect
              to={{
                pathname: "/game/NFTBloky/index.html"
              }}
            />} 
          />
          several other ways
          <Route
            exact
            path="/NFTBloky"
            component={
              loading
              ? () => <div>Loading posts...</div>
            : () => <Home posts={posts} />
            }
          />
         <Route path="/index.html" render={reload} />
        */ }
          </Switch>
    </Router>
    }
    </>
  );
}

export default App;
