import { ReactNode } from "react";
import { Box, Flex, Image, Button, Text, Stack, HStack, useColorMode, useColorModeValue, useDisclosure, LinkBox, LinkOverlay } from "@chakra-ui/react";
import { MoonIcon, SunIcon } from '@chakra-ui/icons';
import ConnectButton from "./ConnectButton";
import AccountModal from "./AccountModal";
import { FaDiscord, FaInstagram, FaTelegram, FaTwitter, FaYoutube } from 'react-icons/fa';
import SocialButton from "./ScocialButton"

type Props = {
  children?: ReactNode;
};

export default function NavBar() {
    const { colorMode, toggleColorMode } = useColorMode();
    const { isOpen, onOpen, onClose } = useDisclosure();

 return (
   <Box bg={useColorModeValue('gray.900', 'gray.900')} px={4} py={2} mr={100} mb={5}>
        <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
       <LinkBox><HStack><Box><Image htmlWidth='72px' htmlHeight='72px' src='/logo.png' /></Box><LinkOverlay href='/'><Text>BitBikin</Text></LinkOverlay>
         {window.location.hostname.indexOf('testnet') !== -1 ?
           <Text >Testnet</Text>
           :
           <></>
         }
       </HStack></LinkBox>
       <HStack>
         <SocialButton label={'Twitter'} href={'https://twitter.com/BitBikin'}>
         <FaTwitter />
       </SocialButton>
         <SocialButton label={'Discord'} href={'https://discord.gg/Y8hCqEUH'}>
         <FaDiscord />
       </SocialButton>
       </HStack>
          <Flex alignItems={'center'}>
            <Stack direction={'row'} spacing={7}>
                <ConnectButton handleOpenModal={onOpen} />        
           <AccountModal isOpen={isOpen} onClose={onClose} />
              <Button onClick={toggleColorMode}>
                {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
              </Button>
              </Stack>
          </Flex>
        </Flex>
</Box>
 );
}