/**
 * Create and hexadecimal string corresponding to given deciman number.
 **/
 export function dec_to_hex_string(dec, length) {
    var hex = dec.toString(16).toUpperCase();
    if (hex.length < length) {
        hex = new Array( length - hex.length + 1 ).join( '0' ) + hex;
    }
    return hex;
}

export function rgba_to_hex_string(r,g,b, alpha_percentage) {    
    let rgb_array =[r,g,b]

    if (typeof alpha_percentage !== 'undefined') {
        var alpha = 255 * alpha_percentage / 100;
        alpha = Math.floor(alpha);
        // Apply alpha value replacing existing if there.
        rgb_array[3] = alpha;
    }
    var hex_string = '';
    for( var i = 0; i < rgb_array.length; i++) {
        hex_string += dec_to_hex_string(rgb_array[i], 2);
    }
    return '#' + hex_string;
}

export function shortenHexString(hexString) {
    if (hexString.length < 12) return hexString
    return hexString.substring(0, 6) + '....' + hexString.substring(hexString.length - 5, hexString.length)
}

//https://codegolf.stackexchange.com/questions/45302/random-golf-of-the-day-1-shuffle-an-array
//F=a=>a.map((v,i)=>a[a[i]=a[j=0|i+Math.random()*(a.length-i)],j]=v)
/* eslint-disable no-unused-expressions */
export const shuffleArray = (a) => {
    var j;
    a.map((c, i) => (a[i] = a[j = Math.random() * ++i | 0], a[j] = c)),a
}
 
export default {
    dec_to_hex_string,
    rgba_to_hex_string,
    shortenHexString,
    shuffleArray
}
